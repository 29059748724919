import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './CircularLoader.module.css';

export default function CircularLoader({
    containerStyles,
    spinnerStyles,
    spinnerOptions,
    dialogText,
}) {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                ...containerStyles,
            }}
        >
            <div
                className={`${styles['container']}${
                    dialogText ? ` ${styles['dialog']}` : ''
                }`}
            >
                <CircularProgress
                    style={{ ...spinnerStyles }}
                    {...spinnerOptions}
                />
                {dialogText && <p>{dialogText}</p>}
            </div>
        </div>
    );
}
