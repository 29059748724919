import { configureStore } from '@reduxjs/toolkit';
import { snackbarsReducer } from './snackbarsSlice/snackbarsSlice';

const store = configureStore({
    reducer: {
        snackbars: snackbarsReducer,
    },
});

export default store;
