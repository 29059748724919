import styles from './StatusContainer.module.css';
import StatusCard from '../statusCard/StatusCard';

function StatusContainer({ data }) {
    return (
        <div className={styles['container']}>
            <p className={styles['status-heading']}>BFS Status page</p>
            <div className={styles['status-container']}>
                {data?.map((el) => (
                    <StatusCard key={el.name} status={el.status && el.status} serviceName={el.name} issue={el.status === 'firing' && el.instance} />
                ))}
            </div>
        </div>
    );
}

export default StatusContainer;
