import styles from './StatusCard.module.css';
import logoAIQ from 'assets/logo-AIQ.png';
import logoAlert from 'assets/logo-Alert.png';
import logoAlisa from 'assets/logo-Alisa.png';
import logoLasta from 'assets/logo-Lasta.png';
import logoSSO from 'assets/logo-SSO.png';
import logoBEEngineering from 'assets/logo-BEEngineering.png';
import logoAPI from 'assets/logo-API.png';
import logoRabbitMQ from 'assets/logo-RabbitMQ.png';
import logoJenkins from 'assets/logo-Jenkins.png';
import logoKurento from 'assets/logo-Kurento.png';
import logoCvat from 'assets/logo-Cvat.png';
import logoGreatTelepresence from 'assets/logo-GreatTelepresence.png';
import logoStreamProxy from 'assets/logo-StreamProxy.png';
import logoFenix from 'assets/logo-Fenix.png';

// TODO  make component presentational

function StatusCard({ status, serviceName, issue }) {
    let image;
    switch (serviceName) {
        case 'alisa-staging-server':
        case 'alisa-develop-server':
        case 'alisa-production-server':
            image = logoAlisa;
            break;
        case 'alisa-staging':
        case 'alisa-develop':
        case 'alisa-production':
            image = logoAlisa;
            break;
        case 'aiq-staging':
        case 'aiq-develop':
        case 'aiq-production':
            image = logoAIQ;
            break;
        case 'aiq-talk-staging':
        case 'aiq-talk-develop':
        case 'aiq-talk-production':
            image = logoAIQ;
            break;
        case 'lasta-staging':
        case 'lasta-develop':
        case 'lasta-production':
            image = logoLasta;
            break;
        case 'sso-staging':
        case 'sso-develop':
        case 'sso-production':
            image = logoSSO;
            break;
        case 'turn-main':
            image = logoBEEngineering;
            break;
        case 'turn-main-server':
            image = logoBEEngineering;
            break;
        case 'api-production':
            image = logoAPI;
            break;
        case 'rabbitmq-staging':
        case 'rabbitmq-develop':
        case 'rabbitmq-production':
            image = logoRabbitMQ;
            break;
        case 'jenkins-main':
            image = logoJenkins;
            break;
        case 'kms-staging':
        case 'kms-ninja':
        case 'kms-staging-server':
        case 'kmsca-production':
        case 'kmsca-production-server':
            image = logoKurento;
            break;
        case 'cvat-staging':
        case 'cvat-develop':
        case 'cvat-production':
            image = logoCvat;
            break;
        case 'alert-staging':
        case 'alert-develop':
        case 'alert-production':
            image = logoAlert;
            break;
        case 'gu-staging':
        case 'gu-develop':
        case 'gu-production':
            image = logoGreatTelepresence;
            break;
        case 'rtsp-proxy-main':
        case 'rtsp-proxy-main-server':
            image = logoStreamProxy;
            break;
        case 'fenix-staging':
        case 'fenix-develop':
        case 'fenix-production':
            image = logoFenix;
            break;
        default:
            image = logoAPI;
    }

    const renderAlertCell = () => {
        const spanStyle = {
            background: status === 'firing' ? 'red' : 'green',
        };

        return <span className={styles['circle']} style={spanStyle}></span>;
    };

    return (
        <div className={styles['card-container']}>
            <div className={styles['status-container']}>
                <div className={styles['icon-container']}>
                    <img className={styles['status-image']} alt="service logo" src={image} />
                    <p className={styles['app-name']}>{serviceName}</p>
                </div>
                <div>{renderAlertCell()}</div>
            </div>
            <p className={styles['status-text']}>{issue}</p>
        </div>
    );
}

export default StatusCard;
