import styles from './SectionDivider.module.css';

function SectionDivider({ title, containerStyles, titleStyles }) {
    const containerClasses = `${styles.divider}${
        containerStyles ? ` ${containerStyles}` : ''
    }`;

    const titleClasses = `${styles['divider-text']}${
        titleStyles ? ` ${titleStyles}` : ''
    }`;

    return (
        <div className={containerClasses}>
            {title && <p className={titleClasses}>{title}</p>}
        </div>
    );
}

export default SectionDivider;
