export const statusTableConfig = [
    {
        caption: 'Timestamp Start',
        dataField: 'startsAt',
        dataType: 'string',
        editable: false,
    },
    {
        caption: 'Timestamp End',
        dataField: 'endsAt',
        dataType: 'string',
        editable: false,
    },
    {
        caption: 'Service Name',
        dataField: 'name',
        dataType: 'string',
        editable: false,
    },
    {
        caption: 'Instance',
        dataField: 'instance',
        dataType: 'string',
        editable: false,
    },
    {
        caption: 'Status',
        dataField: 'status',
        dataType: 'string',
        editable: false,
    },
    {
        caption: 'Description',
        dataField: 'summary',
        dataType: 'string',
        editable: false,
    },
];
