import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Notifier from 'components/notifications/Notifier';
import store from './store/store.js';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={15} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} preventDuplicate={true}>
            <BrowserRouter>
                <App />
                <Notifier />
            </BrowserRouter>
        </SnackbarProvider>
    </Provider>
);
