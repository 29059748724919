import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

const NotificationDismissButton = function ({ notificationId }) {
    const { closeSnackbar } = useSnackbar();
    return (
        <Button
            sx={{
                color: '#e37444',
            }}
            onClick={() => closeSnackbar(notificationId)}
        >
            Dismiss
        </Button>
    );
};

export default NotificationDismissButton;

export const action = (key) => <NotificationDismissButton notificationId={key} />;
