import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import CircularLoader from './components/loader/CircularLoader';
import HomePage from './components/homePage/HomePage';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

function App() {
    return (
        <Suspense fallback={<CircularLoader />}>
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </Suspense>
    );
}

export default App;
