import React, { useRef } from 'react';

import DataGridTable from '../dataGridTable/DataGridTable';
import { Column } from 'devextreme-react/data-grid';
import styles from './TableComponent.module.css';

function TableComponent({ tableList, tableConfig, optionChangedHandler, selectedId }) {
    const tableRef = useRef();
    const renderAlertCell = (data) => {
        const spanStyle = {
            background: data.value === 'firing' ? 'red' : 'green',
        };

        return <span className={styles['dot-circle']} style={spanStyle}></span>;
    };

    const columns = tableConfig.map((field) => {
        if (field.caption === 'Service Name') {
            return <Column key={field.caption} {...field} groupIndex={0} />;
        } else {
            return field.caption === 'Status' ? (
                <Column key={field.caption} {...field} cellRender={renderAlertCell} />
            ) : (
                <Column key={field.caption} {...field} />
            );
        }
    });

    return (
        <div className={styles['table-container']}>
            {tableList && (
                <DataGridTable
                    data={tableList}
                    columns={columns}
                    rowsPerPage={30}
                    optionChangedHandler={optionChangedHandler}
                    focusedRowKey={selectedId}
                    ref={tableRef}
                />
            )}
        </div>
    );
}

export default TableComponent;
