import { put } from 'redux-saga/effects';
import { snackbarActions } from '../store/snackbarsSlice/snackbarsSlice';

export function* notificationHandler({ err, action, title, variant = 'error' }) {
    if (action)
        yield put(
            action({
                title: title,
                message: err.message,
                code: err.code,
            })
        );

    if (err?.response?.status === 401) return;
    const errorDetails = err ? `|| CODE: ${err.code}  ||  MESSAGE: ${err.message}` : '';

    yield put(
        snackbarActions.enqueueSnackbar({
            message: `${title.toUpperCase()} ${errorDetails}`,
            options: {
                variant: variant,
            },
        })
    );
}

export function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
