import { createSelector } from '@reduxjs/toolkit';

const selectSnackbarsSlice = (state) => state.snackbars;

export const snackbarsSelector = createSelector(
    [selectSnackbarsSlice],
    (snackbarsSlice) => snackbarsSlice.snackbars
);

export const displayedSnackbarsSelector = createSelector(
    [selectSnackbarsSlice],
    (snackbarsSlice) => snackbarsSlice.displayed
);
