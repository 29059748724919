import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as snackbarSelectors from 'store/snackbarsSlice/snackbarsSelectors';
import { useEffect } from 'react';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import NotificationDismissButton from 'components/notificationDissmisButton/NotificationDismissButton';

function Notifier() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const displayed = useSelector(snackbarSelectors.displayedSnackbarsSelector);
    const snackbars = useSelector(snackbarSelectors.snackbarsSelector);
    useEffect(() => {
        snackbars.forEach(({ key, message, options = {} }) => {
            // Do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            const action = (key) => (
                <>
                    {options?.action}
                    <NotificationDismissButton notificationId={key} />
                </>
            );

            // Display snackbar using notistack
            enqueueSnackbar(message, {
                ...options,
                action,
                onClose: (event, reason, key) => {
                    if (options.onClose) {
                        options.onClose(event, reason, key);
                    }
                    // Dispatch action to remove snackbar from redux store
                    dispatch(snackbarActions.removeSnackbar(key));
                },
                onExited: () => dispatch(snackbarActions.removeSnackbar(key)),
            });
            // Keep track of snackbars that we've displayed
            dispatch(snackbarActions.updateDisplayedSnackbars(key));

            return () => {
                snackbars.forEach((snackbar) => {
                    if (snackbar.dissmised) dispatch(snackbarActions.removeSnackbar(key));
                });
            };
        });
    }, [snackbars, dispatch, enqueueSnackbar, displayed]);
    return null;
}

export default Notifier;
