import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

function DrawerListItem({ onClick, Icon, className }) {
    return (
        <ListItemButton className={className} onClick={onClick}>
            <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        </ListItemButton>
    );
}

export default DrawerListItem;
