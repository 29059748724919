import axios from 'axios';

export const PROFILE = import.meta.env.VITE_ENV;

const service_port = import.meta.env.VITE_SERVICE_PORT;
const host_name = import.meta.env.VITE_HOSTNAME;

const protocol_http = import.meta.env.VITE_PROTOCOL;
const protocol_ws = import.meta.env.VITE_PROTOCOL_WS;

const isLocalHost = host_name === 'localhost';

const domain = process.env.NODE_ENV === 'test' || !isLocalHost ? host_name : 'localhost:' + service_port;

const main_address_http = protocol_http + domain;
const main_address_wss = protocol_ws + domain;

const socket = main_address_wss + '/socket';

function getServiceUrlSuffix() {
    switch (PROFILE) {
        case 'production':
            return '';
        case 'staging':
            return '-staging';
        default:
            return '-develop';
    }
}

const service_url_suffix = getServiceUrlSuffix();

export const GENERAL = {
    ADDRESSES: {
        DOMAIN: domain,
        DOMAIN_MAIN: window.location.hostname,

        HTTP: {
            MAIN: main_address_http,
            HEALTH: main_address_http + '/health',
        },

        WSS: {
            WEBSOCKET: socket,
        },
        SSO: `https://sso${service_url_suffix}.birdseyesecurity.com/`,
        ALERT: `wss://alert${service_url_suffix}.birdseyesecurity.com/socket`,
    },

    PREFIXES: {
        STORAGE: 'bfs_aiq_',
    },

    ENV: process.env.NODE_ENV,
};

export const locationTransactionsTableRowsPerPage = 21;
export const allLocationsTransactionsTableRowsPerPage = 15;

export const axiosAlert = axios.create({
    baseURL: GENERAL.ADDRESSES.HTTP.MAIN,
});
