import React from 'react';
import DataGrid, { Paging, SearchPanel, FilterRow, HeaderFilter, Export } from 'devextreme-react/data-grid';

function DataGridTable({ classes, data, columns, rowsPerPage, optionChangedHandler, disabled, focusedRowKey }, ref) {
    const focusedRow = focusedRowKey ? { focusedRowKey } : null;

    return (
        <div>
            <DataGrid
                ref={ref}
                disabled={disabled}
                dataSource={data}
                keyExpr="id"
                allowColumnReordering={false}
                showBorders
                focusedRowEnabled={true}
                allowColumnResizing={true}
                columnResizingMode="widget"
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                loadPanel={{ enabled: true }}
                onOptionChanged={optionChangedHandler}
                {...focusedRow}
                className={classes}
            >
                <SearchPanel visible={true} />
                <Paging defaultPageSize={rowsPerPage} mode="full" showNavigtionButtons={true} />

                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {columns}

                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        </div>
    );
}

export default React.forwardRef(DataGridTable);
