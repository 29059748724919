import { useEffect, useState } from 'react';
import TableComponent from '../tableComponent/TableComponent';
import SectionDivider from '../sectionDivider/SectionDivider';
import StatusContainer from '../statusContainer/StatusContainer';
import DrawerListItem from '../drawerListItem/DrawerListItem';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import { getCookie } from 'utils/utils';
import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button';
import { axiosAlert, GENERAL } from 'config';
import { statusTableConfig } from '../tableConfig';
import { useDispatch } from 'react-redux';
import { PROFILE } from 'config';
import styles from './HomePage.module.css';
import { Client } from '@stomp/stompjs';

const ssoAddress = GENERAL.ADDRESSES.SSO;
const webSocketGeneralConfig = {
    timesToTryReconnect: 500,
    reconnectDelay: 10000,
};

const stompClientConfig = {
    brokerURL: GENERAL.ADDRESSES.ALERT,
    debug: function (str) {
        if (str !== 'Received data' && str !== '<<< PONG' && str !== '>>> PING') console.log(str);
    },

    reconnectDelay: webSocketGeneralConfig.reconnectDelay, //in case ws connection fails or disconected, recconection will be tried [reconnectDelay]ms. Seems to be infinite loop of retries
    connectionTimeout: 20000, //it may occur that connection is succesful, but we never get back response on 'CONNECT' frame sent, in that case after [connectionTimeout]ms ws connection is closed and  new reconnect will be scheduled in [reconnectDelay]ms. Otherwise (without this property) initial ws connection remains open and waits forever.
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
};

function HomePage() {
    const dispatch = useDispatch();
    const [allAlerts, setAllAlerts] = useState([]);
    const [allApps, setAllApps] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const homeButtonClickHandler = () => window.open(ssoAddress);
    const alertClient = new Client(stompClientConfig);

    const findApps = async () => {
        const allApplications = [];
        allApps?.forEach((app) => {
            let appsWithStatus;
            const reverseAlert = [...allAlerts].reverse();
            for (let i = 0; i < reverseAlert.length; i++) {
                if (reverseAlert[i]?.name === app?.name) {
                    appsWithStatus = reverseAlert[i];

                    break;
                } else {
                    appsWithStatus = app;
                }
            }
            allApplications.push(appsWithStatus);
        });
        setAllApps(allApplications);
    };

    const getAplications = async function () {
        try {
            const response = await axiosAlert.get(`/component/all`);
            if (response?.status === 200) {
                setAllApps(response.data);
            }
        } catch {
            console.log('Error something is not working');
        }
    };

    const optionChangedHandler = (e) => {
        if (e.name === 'focusedRowKey') {
            setSelectedRow(e.value);
        }
    };

    const removeCookie = () => {
        switch (PROFILE) {
            case 'development':
                document.cookie = 'alertD=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            case 'staging':
                document.cookie = 'alertS=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            case 'production':
                document.cookie = 'alertP=AlertsON; path=/; domain=.birdseyesecurity.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                break;
            default:
                document.cookie = 'alert=AlertsON; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    };

    const hasNotification = (alerts, alert) => {
        const notification = !alerts.some((el) => el.id === alert.id && el.status === alert.status);
        return notification;
    };

    const showChromeNotification = (alert) => {
        const time = `${alert.startsAt?.slice(0, 10)} ${alert.startsAt?.slice(11, 16)}`;
        const notification = new Notification(`${alert.name} service is ${alert.status === 'firing' ? 'down' : 'up'}, instance ${alert.instance} ${alert.startsAt ? `, since ${time}` : ''}`);
    };
    const showNotifications = (newAlert, alertCookie) => {
        const time = `${newAlert.startsAt?.slice(0, 10)} ${newAlert.startsAt?.slice(11, 16)}`;

        setAllAlerts((prev) => {
            // if (hasNotification(prev, newAlert)) {
            const modifiedData = {
                ...newAlert,
                startsAt: `${newAlert.startsAt.slice(0, 10)} ${newAlert.startsAt.slice(11, 16)}`,
                endsAt: `${newAlert.endsAt.slice(0, 10)} ${newAlert.endsAt.slice(11, 16)}`,
            };
            return [...prev, modifiedData];
            // } else {
            //     return [...prev];
            // }
        });

        dispatch(
            snackbarActions.enqueueSnackbar({
                message: `${newAlert.name} service is ${newAlert.status === 'firing' ? 'down' : 'up'}, instance ${newAlert.instance}${newAlert.startsAt ? `, since ${time}` : ''}`,
                options: { variant: `${newAlert.status === 'firing' ? 'error' : 'success'}` },
            })
        );

        Notification.requestPermission();
        if (Notification.permission === 'granted' && alertCookie) {
            showChromeNotification(newAlert);
            return;
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted' && alertCookie) {
                    showChromeNotification(newAlert);
                }
            });
        }
    };

    const activateStopConnection = () => {
        alertClient.activate();
        let cookieAlert;

        console.log(PROFILE);
        switch (PROFILE) {
            case 'development':
                cookieAlert = getCookie('alertD');
                break;
            case 'staging':
                cookieAlert = getCookie('alertS');
                break;
            case 'production':
                cookieAlert = getCookie('alertP');
                break;
            default:
                cookieAlert = getCookie('alert');
        }
        alertClient.onConnect = () => {
            alertClient.subscribe('/user/topic/all', (message) => {
                const newAlert = JSON.parse(message.body);
                showNotifications(newAlert, cookieAlert);
            });

            alertClient.subscribe('/topic/all', (message) => {
                const newAlert = JSON.parse(message.body);
                showNotifications(newAlert, cookieAlert);
            });
        };
    };
    useEffect(() => {
        getAplications();
        let cookieLasta;
        let cookieSso;
        let cookieAiq;
        let cookieAlert;
        let cookieGu;

        switch (PROFILE) {
            case 'development':
                cookieLasta = getCookie('lastaD');
                cookieSso = getCookie('ssoD');
                cookieAiq = getCookie('aiqD');
                cookieAlert = getCookie('alertD');
                cookieGu = getCookie('guD');
                break;
            case 'staging':
                cookieLasta = getCookie('lastaS');
                cookieSso = getCookie('ssoS');
                cookieAiq = getCookie('aiqS');
                cookieAlert = getCookie('alertS');
                cookieGu = getCookie('guS');
                break;
            case 'production':
                cookieLasta = getCookie('lastaP');
                cookieSso = getCookie('ssoP');
                cookieAiq = getCookie('aiqP');
                cookieAlert = getCookie('alertP');
                cookieGu = getCookie('guP');
                break;
            default:
                cookieLasta = getCookie('lasta');
                cookieSso = getCookie('sso');
                cookieAiq = getCookie('aiq');
                cookieAlert = getCookie('alert');
                cookieGu = getCookie('gu');
        }

        if (cookieSso || cookieAiq || cookieAlert || cookieGu || cookieLasta) {
            activateStopConnection();

            return;
        } else {
            switch (PROFILE) {
                case 'development':
                    document.cookie = 'alertD=AlertsON; path=/; ';
                    break;
                case 'staging':
                    document.cookie = 'alertS=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                case 'production':
                    document.cookie = 'alertP=AlertsON; path=/; domain=.birdseyesecurity.com; ';
                    break;
                default:
                    document.cookie = 'alert=AlertsON; path=/; ';
            }
            activateStopConnection();
        }

        window.addEventListener('beforeunload', removeCookie);
        return () => {
            window.removeEventListener('beforeunload', removeCookie);
            alertClient.deactivate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        findApps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allAlerts]);

    return (
        <div className={styles['main-container']}>
            <DrawerListItem className={styles['icon-container']} listItemComponent={Button} onClick={homeButtonClickHandler} Icon={HomeIcon} />
            <StatusContainer data={allApps} />
            <SectionDivider title="Alert List" />
            <TableComponent tableList={allAlerts} tableConfig={statusTableConfig} optionChangedHandler={optionChangedHandler} selectedId={selectedRow?.id} />
        </div>
    );
}

export default HomePage;
